// @media screen and (max-width: 767px) {
//   .resultado-imagen, video {
//     object-fit: cover;
//   }
// }
.resultado-imagen, video {
  object-fit: cover;
}

.main-content {
  position: relative;
}

.loading {
  position: absolute;
  width: 100%;
  height: 100%;

  &-container {
    width: 100%;
    height: 100%;
    background: #09FA;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  &-content {
    position: sticky;
    top: 200px; 
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  &-text {
    margin-bottom: 10px;
    color: #fff;
    font-family: proxima-soft, sans-serif;
    font-weight: 600;
    font-size: 25px;
  }

  &.none {
    display: none;
  }
}

.dot-pulse {
  position: relative;
  left: -9999px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #fff;
  color: #fff;
  box-shadow: 9999px 0 0 -5px;
  animation: dot-pulse 1.5s infinite linear;
  animation-delay: 0.25s;
}
.dot-pulse::before, .dot-pulse::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #fff;
  color: #fff;
}
.dot-pulse::before {
  box-shadow: 9984px 0 0 -5px;
  animation: dot-pulse-before 1.5s infinite linear;
  animation-delay: 0s;
}
.dot-pulse::after {
  box-shadow: 10014px 0 0 -5px;
  animation: dot-pulse-after 1.5s infinite linear;
  animation-delay: 0.5s;
}

@keyframes dot-pulse-before {
  0% {
    box-shadow: 9984px 0 0 -5px;
  }
  30% {
    box-shadow: 9984px 0 0 2px;
  }
  60%, 100% {
    box-shadow: 9984px 0 0 -5px;
  }
}
@keyframes dot-pulse {
  0% {
    box-shadow: 9999px 0 0 -5px;
  }
  30% {
    box-shadow: 9999px 0 0 2px;
  }
  60%, 100% {
    box-shadow: 9999px 0 0 -5px;
  }
}
@keyframes dot-pulse-after {
  0% {
    box-shadow: 10014px 0 0 -5px;
  }
  30% {
    box-shadow: 10014px 0 0 2px;
  }
  60%, 100% {
    box-shadow: 10014px 0 0 -5px;
  }
}
